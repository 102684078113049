h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	-webkit-appearance: none;
}

.hidden-assistive-content-wcag {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

/* Pagination */
.pagination-wrapper {
	overflow: hidden;
}

.pagination-wrapper * {
	box-sizing: border-box;
}

.pagination-wrapper > * {
	line-height: 40px;
}

.pagination-wrapper .pagination-filter {
	float: left;
	width: 100%;
}

.pagination-wrapper .pagination-sort-items > * {
	float: left;
}

.pagination-wrapper .pagination-sort-items .fly-out-menu-container_SortBy {
	margin: 0;
}

.pagination-wrapper .pagination-sort-items > .views > *:first-child {
	padding-right: 5px;
}

.pagination-wrapper .pagination-sort-items > .views img,
.pagination-wrapper .pagination-sort-items > .views a img {
	vertical-align: middle;
}

.pagination-wrapper .pagination-sort-items.single-page {
	width: 100%;
}

.pagination-wrapper .pagination-sort-items.single-page .views {
	float: right;
}

.pagination-wrapper .pagination-current-page label,
.pagination-wrapper .pagination-items-per-page label {
	display: inline-block !important;
	font-weight: normal !important;
	line-height: inherit;
	margin: 0;
	padding: 0;
	width: auto !important;
}

.pagination-wrapper .pagination-current-page select {
	padding: 0;
	display: inline-block;
	width: auto !important;
	margin: 0;
}

.pagination-wrapper .pagination-previous-page {
	float: left;
	width: 20%;
	padding: 0 0 0 8px;
}

.pagination-wrapper .pagination-page-selection-bottom {
	float: left;
	width: 60%;
	text-align: center;
}

.pagination-wrapper .pagination-next-page {
	float: left;
	width: 20%;
	padding: 0 8px 0 0;
	text-align: right;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #757575;
	font-style: italic;
}

@media screen and (max-width: 767px) {
	table.responsive td {
		display: block;
		width: 100%;
	}
}

/* Mobile Portrait */
@media screen and (max-width: 480px) {
	.pagination-wrapper .pagination-sort-items {
		padding: 0;
	}

	.pagination-wrapper .pagination-sort-items .views {
		float: right;
	}

	.pagination-wrapper .pagination-current-items-top {
		clear: both;
		width: 100%;
		margin: 5px 0 5px 0;
		text-align: center;
	}

	.pagination-wrapper .pagination-page-selection-bottom .pagination-current-items-bottom {
		display: none;
	}
}

/* Mobile Landscape */
@media screen and (min-width: 481px) and (max-width: 720px) {
	.pagination-wrapper .pagination-filter {
		width: 50%;
	}

	.pagination-wrapper .pagination-sort-items {
		padding: 0;
	}

	.pagination-wrapper .pagination-sort-items .views {
		float: none;
	}

	.pagination-wrapper .pagination-page-selection-top {
		display: none;
	}

	.pagination-wrapper .pagination-current-items-top {
		text-align: right;
		padding: 0 8px 0 0;
	}

	.pagination-wrapper .pagination-page-selection-bottom .pagination-current-items-bottom {
		display: none;
	}
}

/* Tablet */
@media screen and (min-width: 721px) {
	.pagination-wrapper .pagination-sort-items {
		width: 32%;
		padding: 0;
	}

	.pagination-wrapper .pagination-page-selection-top {
		text-align: center;
		padding: 0 0 0 8px;
	}

	.pagination-wrapper .pagination-page-selection-top > *,
	.pagination-wrapper .pagination-page-selection-bottom > * {
		display: inline-block;
	}

	.pagination-wrapper .pagination-page-selection-bottom .pagination-current-items-bottom {
		padding-left: 15px;
	}

	.pagination-wrapper .pagination-items-per-page {
		padding-left: 5px;
	}

	.pagination-wrapper .pagination-current-items-top {
		float: left;
		width: 30%;
		padding: 0 8px 0 0;
		text-align: right;
	}
}

/* breakpoints */

[data-breakpoints] {
	display: none;
}

@media screen and ( min-width: 1106px ) {
	[data-breakpoints*="xl"] {
		display: block;
	}
}

@media screen and ( min-width: 735px ) {
	[data-breakpoints*="md,lg"] {
		display: block;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block;
}


/*** Accessible Select Menu ***/

.fly-out-menu-container {
	padding: 0;
	margin: 0;
	position: relative;
	background: white;
	box-sizing: border-box;
}

.fly-out-menu-container * {
	box-sizing: border-box;
}

.fly-out-menu-container input[type=text]::-ms-clear {
	display: none;
}

.fly-out-menu-list-container {
	border: 1px solid #bfbfbf;
	background: white;
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	width: auto;
	box-sizing: border-box;
	display: none;
	position: absolute;
	z-index: 9998; /* jQuery Tooltip is 9999 */
	box-shadow: 0px 5px 7px #00000015;
	margin-top: 1px;
	border-top: 0;
}

.fly-out-menu-list-container .fly-out-menu-list {
	padding: 0;
	margin: 0;
}

.fly-out-menu-list-container * {
	box-sizing: border-box;
}

.fly-out-menu-list-container ul.fly-out-menu-list li {
	margin: 0;
	list-style-type: none;
	line-height: 1.25em;
	padding: 0;
	text-align: left;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a {
	width: 100%;
	text-decoration: none;
	margin: 0;
	padding: 0 5px 0 8px;
	line-height: 1.5;
	border: 1px solid transparent !important;
	font-size: inherit !important;
	color: #000000 !important;
	font-weight: normal;
	white-space: nowrap;
	display: block;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li a {
	font-size: 1rem;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a:focus,
.fly-out-menu-list-container ul.fly-out-menu-list li a:hover {
	background: #ececec !important;
	color: #000000 !important;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #1f78d1 !important;
	color: #ffffff !important;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a:active {
	transition: none;
}

.fly-out-menu-container .fly-out-menu-button {
	padding: 4px 0 4px 8px;
	padding-right: 0 !important;
	line-height: 1.25em;
	cursor: pointer;
	border: 1px solid #bfbfbf;
	width: 100%;
	position: relative;
	background: white;
	overflow: hidden;
	white-space: nowrap;
}

.fly-out-menu-container .fly-out-menu-button a {
	display: block;
}

.fly-out-menu-container .selection a {
	padding-right: 25px !important; /* this should be the same as the arrow width */
	overflow: hidden;
	text-overflow: ellipsis;
}

.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	position: absolute;
	top: 1px;
	bottom: 1px;
	right: 1px;
	padding: 0;
	background: inherit;
	font-size: 1.25rem;
	border-radius: 2px;
}

.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down:before {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 23px; /* this should be the same (-2) as the link's right padding */
	height: 100%;
}

.fly-out-menu-container.open-dropdown-menu .fly-out-menu-button {
	border: 1px solid #5e88cd;
}

.fly-out-menu-button div.js-selection a.fly-out-link-a,
.fly-out-menu-button div.js-selection a.fly-out-link-a:hover,
.fly-out-menu-button div.js-selection a.fly-out-link-a:focus {
	color: #000;
	text-decoration-line: none;
}

@media screen and ( max-width: 767px ) {

	.fly-out-menu-list-container ul.fly-out-menu-list li {
		border-bottom: 1px solid grey;
		font-size: 1.25rem;
		line-height: 1.33rem;
	}

	.fly-out-menu-list-container ul.fly-out-menu-list li a {
		padding: 20px;
	}

	.fly-out-menu-list-background {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1042;
		overflow: hidden;
		position: fixed;
		background: #0b0b0b;
		opacity: 0.8;
	}

	html.disable-scrolling {
		overflow: hidden;
	}
	/* this is needed to prevent the background content from expanding to the hidden scrollbar's space */
	html.disable-scrolling body {
		overflow: scroll;
	}
}

#fly-out-menu-list-background {
	top: 0;
	background: rgba(0, 0, 0, 0.75);
	position: fixed;
	z-index: 10000;
	width: 100%;
	height: 100%;
	transition: all 0.5s ease;
}

#fly-out-menu-list-background .wrapper {
	margin: 7%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
}

#fly-out-menu-list-background .fly-out-menu-list-container {
	background: #ffffff;
	box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.5);
	min-width: 100% !important;
	width: 100%;
	position: relative !important;
	max-height: 100%;
	height: auto;
	top: 0 !important;
	left: 0 !important;
	border: 0;
}

/*********************************
	Pagination
*********************************/
div.pagination-wrapper {
	display: flex;
}

.pagination-filter.pagination-bg.pagination-sort-items {
	display: flex;
	width: auto;
	align-items: center;
}

.pagination-filter.pagination-bg.pagination-page-selection-top {
	display: flex;
	width: auto;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
}

.pagination-filter.pagination-bg.pagination-page-selection-top .pagination-current-page {
	display: flex;
	width: auto;
	align-items: center;
}

.pagination-items-per-page .pagination-items-per-page {
	display: flex;
	flex-grow: 1;
}

.pagination-filter.pagination-bg.pagination-page-selection-top .pagination-items-per-page {
	display: flex;
	width: auto;
	align-items: center;
	padding-left: 15px;
}

.pagination-filter.pagination-bg.pagination-current-items-top {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: auto;
}

.pagination-wrapper .fly-out-menu-container {
	margin: 0 5px;
}

.pagination-previous-page.pagination-bg {
	display: flex;
	width: auto;
	align-items: center;
}

.pagination-page-selection-bottom.pagination-bg {
	display: flex;
	width: auto;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
}

.pagination-next-page.pagination-bg {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: auto;
}

.pagination-page-selection-bottom.pagination-bg .pagination-current-page {
	display: flex;
	width: auto;
	align-items: center;
	justify-content: center;
}

.pagination-wrapper .pagination-filter .views {
	margin: 0 5px;
}

/* Make the fly-out-menu as simple as possible */
.fly-out-menu-container .fly-out-menu-button .selection.js-selection a {
	border-bottom: 0 !important;
	color: black !important;
	font-weight: normal !important;
	text-decoration: none !important;
	line-height: inherit !important;
	font-size: inherit !important;
}

@media (max-width: 767px) {
	.pagination-wrapper,
	.fly-out-menu-container .fly-out-menu-button .selection.js-selection a {
		font-size: 0.875em;
	}

	.pagination-wrapper .pagination-filter.pagination-bg.pagination-page-selection-top {
		display: none;
	}

	.pagination-filter.pagination-bg.pagination-current-items-top {
		flex-grow: 1;
	}
}

@media (max-width: 497px) {
	.pagination-wrapper {
		flex-direction: column;
		align-items: center;
	}

	.pagination-wrapper .pagination-filter.pagination-bg.pagination-page-selection-top {
		display: flex;
	}

	.pagination-previous-page.pagination-bg,
	.pagination-next-page.pagination-bg {
		padding: 0;
	}
}
